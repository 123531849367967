import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Button from "components/Button";
import Fade from "components/Fade";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "fade"
    }}>{`Fade`}</h1>

    <Playground __position={0} __code={'() => {\n  const [fadeIn, setFadeIn] = React.useState(true)\n  const toggle = () => setFadeIn(!fadeIn)\n  return (\n    <>\n      <Button color=\"primary\" onClick={toggle}>\n        Toggle Fade\n      </Button>\n      <Fade in={fadeIn} tag=\"h5\" className=\"mt-3\">\n        This content will fade in and out as the button is pressed\n      </Fade>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Fade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [fadeIn, setFadeIn] = React.useState(true);

        const toggle = () => setFadeIn(!fadeIn);

        return <>
        <Button color="primary" onClick={toggle} mdxType="Button">Toggle Fade</Button>
        <Fade in={fadeIn} tag="h5" className="mt-3" mdxType="Fade">
          This content will fade in and out as the button is pressed
        </Fade>
      </>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/fade/" target="_blank">Reactstrap Fade</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      